<template>
  <div class="bar">
    <div>Movie's Blog</div>
  </div>
  <div id="app">
    <div class="nav">
      <div class="body">
        <img class="head" src="./assets/head.jpg" alt="">
        <span class="name">最酷的</span>
        <router-link to="/" class="label">博客首页</router-link>
        <router-link to="/AddwBlog" class="label">添加博客</router-link>
      </div>
    </div>
    <div class="background">
    </div>
  </div>
  <router-view></router-view>
  <a class="foot" href="https://beian.miit.gov.cn" target="_blank">粤ICP备2022047748号</a>
</template>

<script>
import addBloge from './components/AddBlog';
import showBloge from './components/ShowBlog';
import tempBlog from './components/TempBlog';
import detailBlog from './components/DetailBlog'


export default {
  name: 'app',
  data() {
    return {

    };
  },
  components: {
    addBloge,
    showBloge,
    tempBlog,
    detailBlog
  }
}
</script>


<style>
div {
  margin: 0 auto;
}

.head {
  width: 50px;
  border-radius: 50%;
  vertical-align: middle;
}

.name {
  display: inline-block;
  margin-left: 10px;
  color: #fff;
  text-decoration: underline;
}

.bar {
  height: 148px;
  background-image: url(./assets/1.png);
}

.bar div {
  text-align: center;
  font-size: 34px;
  font-weight: 700;
  color: #072833;
  padding-top: 70px;
}

.nav {
  height: 80px;
  background-color: #072833;
}

.body {
  width: 1110px;
  margin: 0 auto;
  overflow: hidden;
}

.label {
  display: inline-block;
  height: 80px;
  font-size: 18px;
  color: #fff;
  line-height: 80px;
  padding-left: 38px;
}

.label:nth-child(2) {
  padding-left: 38px;

}

.background {
  height: 470px;
  background-image: url(./assets/2.png);
  background-size: 100%;
  background-repeat: no-repeat;
}

.foot {
  float: left;
  width: 100%;
  text-align: center;
  margin: 20px auto;
}
</style>

import { createRouter, createWebHistory } from 'vue-router'

const ShowBlog = () => import('../components/ShowBlog.vue')
const AddwBlog = () => import('../components/AddBlog.vue')
const DetailwBlog = () => import('../components/DetailBlog.vue')
const TempBlog = () => import('../components/TempBlog.vue')

const routes = [
  {
    path: '/',
    name: 'ShowBlog',
    component: ShowBlog
  },
  {
    path: '/AddwBlog',
    name: 'AddwBlog',
    component: AddwBlog
  },
  {
    path: '/DetailwBlog',
    name: 'DetailwBlog',
    component: DetailwBlog
  },
  {
    path: '/TempBlog',
    name: 'TempBlog',
    component: TempBlog
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router

<!--  -->

<template>
<keep-alive>
   <div class="detailBlog">
     <div class="main">
        <h2 class="articleTitle">{{$store.state.detaildata.articleTitle}}</h2>
        <p class="content">{{blog.content}}</p>
        <p class="classification">
         <ul>
           <span>分类：</span>
           <li v-for="(item,index) in $store.state.detaildata.classification" :key="index">{{item}}</li>
         </ul>
        </p>
        <p class="author">作者：{{$store.state.detaildata.author}}</p>
        <button @click="remove(blog.id)" class="delete"></button>
        <router-link to="/TempBlog" @click="editor(blog.id)" class="editor"></router-link>
     </div>
   </div>
</keep-alive>
</template>

<script>
export default {
    name:'detailBlog',
  data () {
    return {
        blog:this.$store.state.detaildata,
    };
  },

  methods: {
    // 删除博客
    remove(index) {
      if(window.confirm('确定删除该博客吗？')) {
        this.$store.state.data.splice(index,1);
        // this.blogs=this.$store.state.data;
        this.$router.go(-1);
      }
    },

    // 编辑博客
    editor(index) {
      // 获取所需编辑项的数据并传到页面上
      this.$store.state.tempdata.articleTitle=this.$store.state.data[index].articleTitle;
      this.$store.state.tempdata.content=this.$store.state.data[index].content;
      this.$store.state.tempdata.classification=this.$store.state.data[index].classification;
      this.$store.state.tempdata.author=this.$store.state.data[index].author;
      this.$store.state.tempdata.id=index;
    },
  }
}

</script>
 
<style scoped>
 .detailBlog {
   width: 1110px;
   text-align: center;
   box-shadow: 0px 0px 10px 0px;
   color: #e7e7e7;
   transform: translateY(-150px);
   background-color: #fff;
 }

 .main {
   width: 1070px;
   text-align: left;
   padding: 20px 0;
   margin: 0 auto;
 }

 .articleTitle {
   width: 777px;
   text-align: center;
   color: #050000;
   font-size: 22pt;
   border-bottom: 1px solid #000;
   margin: 0 auto;
   margin-top: 10px;
   padding-bottom: 12px;
 }

 .content {
   width: 1000px;
   margin: 0 auto;
   color: #050000;
   padding: 15px 0px 12px;
 }

 .classification {
   display: inline-block;
   color: #050000;
   margin-left: 14px;
   margin-top: 4px;
 }

 ul {
   float: left;
   padding-bottom: 4px;
   border-bottom: 1px dashed #746f6f;
 }

 ul>span {
   float: left;
 }

 li {
   float: left;
   list-style: none;
 }

  ul li:nth-of-type(n-1)::after {
    content: '，';
  }

  ul li:last-child::after {
    content: '';
  }

 .author {
   float: left;
   color: #050000;
   margin-left: 14px;
   margin-top: 5px;
   padding-bottom: 4px;
   border-bottom: 1px dashed #746f6f;
 }

 .delete {
   float: right;
   margin-top: 4px;
   border: 0;
   outline: none;
   background-color: transparent;
   font-size: 32px;
   color: #333;
   margin-right: 12px;
   cursor: pointer;
 }

 .editor {
   width: 20px;
   height: 20px;
   float: right;
   margin-top: 4px;
   margin-right: 20px;
   font-size: 32px;
   cursor: pointer;
 }
</style>
<!--  -->
<template>
   <div class="tempBlog">
     <div class="head">
       <div>Let's edit the blog together</div>
     </div>
     <div class="body">
       <!-- 编辑博客 -->
     <div class="main">
       <input type="text" v-model="blog.articleTitle" placeholder="标题" class="articleTitle">
       <h3>内容</h3>
       <textarea v-model="blog.content" placeholder="内容" class="content"></textarea>
       <div class="classify">
         <label>科幻</label>
         <input type="checkbox" value="科幻" v-model="blog.classification">
         <label>悬疑</label>
         <input type="checkbox" value="悬疑" v-model="blog.classification">
         <label>剧情</label>
         <input type="checkbox" value="剧情" v-model="blog.classification">
         <label>经典</label>
         <input type="checkbox" value="经典" v-model="blog.classification">
       </div>

       <input type="text" v-model="blog.author" placeholder="作者" class="author">

       <router-link to="/DetailwBlog" @click="changeData" class="changeData">完成编辑</router-link>
     </div>
       <!-- 预览博客 -->
     <div class="preview">
       <p class="preview-articleTitle">标题:{{blog.articleTitle}}</p>      
       <p class="preview-content">内容：{{blog.content}}</p>
       <p class="preview-classification">
         <ul>
           <li>分类：</li>
           <li v-for="item in blog.classification" :key="item">{{item}}</li>
         </ul>
       </p>
       <p class="preview-author">作者：{{blog.author}}</p>
     </div>
     </div>
   </div>
</template>

<script>
export default {
  name: "tempBlog",
  data() {
    return {
      blog: this.$store.state.tempdata,
    };
  },

  components: {},

  computed: {},

  methods: {
    // 编辑
    changeData() {
      // 储存数据
      this.$store.state.data[this.$store.state.tempdata.id].articleTitle = this.$store.state.tempdata.articleTitle;
      this.$store.state.data[this.$store.state.tempdata.id].content = this.$store.state.tempdata.content;
      this.$store.state.data[this.$store.state.tempdata.id].author = this.$store.state.tempdata.author;
      this.$store.state.data[this.$store.state.tempdata.id].classification = this.$store.state.tempdata.classification;
      // 改变DetailBlog页面的数据
      this.$store.state.detaildata.articleTitle = this.$store.state.data[this.$store.state.detaildata.id].articleTitle;
      this.$store.state.detaildata.content = this.$store.state.data[this.$store.state.detaildata.id].content;
      this.$store.state.detaildata.author = this.$store.state.data[this.$store.state.detaildata.id].author;
      this.$store.state.detaildata.classification = this.$store.state.data[this.$store.state.detaildata.id].classification;
    },
  },
};
</script>
 
<style scoped>
.tempBlog {
  height: 670px;
  text-align: center;
  background-color: #072833;
  margin-top: -671px;
}

.body {
  width: 1300px;
}

.main {
  float: left;
  width: 540px;
  height: 400px;
  background-color: #fff;
  margin-top: 50px;
}

.head {
  height: 172px;
  background-color: #fff;
}

.head div {
  width: 400px;
  padding-top: 98px;
  padding-bottom: 10px;
  font-size: 21px;
  font-weight: 700;
  font-family: "Castellar";
  border-bottom: 1px solid #000;
}

input {
  background-color: #072833;
  font-size: 20px;
  color: #fff;
  outline: none;
  border: none;
  padding-left: 20px;
  vertical-align: middle;
}

.articleTitle {
  width: 470px;
  height: 55px;
  margin-top: 40px;
}

.content {
  background: #072833;
  border: none;
  color: #fff;
  width: 470px;
  height: 145px;
  font-size: 22px;
  padding: 20px;
  overflow: auto;
}

.classify {
  float: right;
  margin-right: 40px;
  margin-top: 16px;
}

.classify input {
  margin-left: 3px;
}

.classify label {
  margin-left: 7px;
}

.author {
  display: block;
  height: 30px;
  margin-left: 35px;
  margin-top: 10px;
}

.changeData {
  display: inline-block;
  float: right;
  width: 150px;
  height: 34px;
  font-size: 22px;
  color: #fff;
  border: none;
  border-radius: 5px;
  background: #ce4109;
  margin-top: 42px;
  margin-right: 18px;
}

.preview {
  color: #fff;
  text-align: left;
  margin-left: 590px;
  margin-top: 72px;
}

.preview-articleTitle {
  font-size: 24px;
  color: rgb(228, 224, 224);
}

.preview-content {
  margin-top: 10px;
  line-height: 24px;
  color: rgb(228, 224, 224);
}

.preview-classification {
  display: inline-block;
  border-bottom: 1px dashed #ececec;
  padding-bottom: 3px;
}

.preview-classification ul li {
  float: left;
  list-style: none;
  margin-top: 16px;
}

.preview-classification ul li::before {
  content: "，";
}

.preview-classification ul li:nth-child(1):before {
  content: "";
}

.preview-classification ul li:nth-child(2):before {
  content: "";
}

.preview-author {
  display: inline-block;
  transform: translateY(-8px);
  margin-left: 28px;
  border-bottom: 1px dashed #ececec;
  padding-bottom: 3px;
}
</style>
import { createStore } from 'vuex'

export default createStore({
  state: {
    data:[
      
      {
        articleTitle:'海蒂和爷爷 Heidi (2015)',
        content:'海蒂失去了父母，于力失去了孩子，他们是两个被抛弃的人，恰好填补了对方缺失的部分。 来到克拉拉家，海蒂有了新衣服、新鞋子，有了食物，有了温暖的大床，她什么都有了，可是没有了爷爷、雪山和羊群。 你给了她物质生活，其实她要的是精神生活，要的是亲情和自由。 无数的规矩和礼仪把克拉拉压得喘不过气，让她产生了心病。不仅身体寸步难行，心理也被牢牢困住。 你以为你给了她一切，其实你根本不知道她想要的是什么。 正是蓝天和白云，青草和蝴蝶，让她打开心扉，融化了她内心的病魔。 当然，城里也有柔软的面包，山里也有人没有见识。城里并非一无是处，山里的人也不一定都是对的。 电影还有一条暗线，老师强迫海蒂认字，然而欲速则不达； 奶奶鼓励她自己看故事，从而培养了她的兴趣。果然没有教不会的学生，只有不会教的老师。',
        classification:['剧情'],
        author:'朝暮雪 '
      },
      {
        articleTitle:'大话西游之大圣娶亲 (1995)',
        content:'鲜花虽会凋谢，但会再开。一生所爱，隐约在白云外。苦海翻起爱恨，在世间难逃避命运。相亲竟不可接近，或我应该相信是命运。',
        classification:['经典'],
        author:'woodYA!!en'
      },
      {
        articleTitle:'驯龙高手3 How to Train Your Dragon: The Hidden World (2019)',
        content:'第三部刻画小嗝嗝的成年视角，从被人一直指摘的“离开这条龙，你就一无是处”到“有爱，就会有失去”，小嗝嗝在无奈和成长的阵痛中选择放手和告别。你喜欢的人，他有一个比你更好的世界，那种欣慰又落寞的纠结，居然被一部动画拍的如此细腻。“我一直考虑我的需求，却从没顾及你的感受” 结尾的人龙大型告别现场，简直就是催泪原子弹。“你回去吧，安全过跟我住” 美轮美奂的世界尽头瀑布，是给你爱的人最好的归属。',
        classification:['剧情'],
        author:'影志'
      },
      {
        articleTitle:'大话西游之大圣娶亲 (1995)',
        content:'鲜花虽会凋谢，但会再开。一生所爱，隐约在白云外。苦海翻起爱恨，在世间难逃避命运。相亲竟不可接近，或我应该相信是命运。',
        classification:['经典'],
        author:'woodYA!!en'
      },
      
    ],
    tempdata:{
      articleTitle:'',
      content:'',
      classification:'',
    },
    detaildata:{
      articleTitle:'',
      content:'',
      classification:'',
      id:''
    }
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})

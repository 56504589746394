<!--  -->
<template>
   <div class="showBlog">
     <!-- <button @click="text">测试</button> -->
     <h1>Blog post</h1>
     <input type="text" v-model="search" placeholder="搜索blog标题" v-if="flag" class="search">
     <input type="text" v-model="search" placeholder="搜索blog内容" v-if="!flag" class="search">
     <button @click="searchMode" class="searchMode"></button>
     <div v-for="(item,index) in searchResults" :key="index" class="blog">
       <!-- <p>{{Math.floor(Math.random()*10)}}</p> -->
       <img src="../assets/3.png" alt="">
       <h2>{{item.articleTitle}}</h2>
       <div class="content">{{item.content}}</div>
        <!-- <button @click="remove(item.id)">delete</button> -->
        <router-link to="/DetailwBlog" @click="detail(item,item.id)" class="detial">></router-link>
      </div>
   </div>
</template>

<script>
export default {
name:'showBlog',
  data () {
    return {
      blogs: this.$store.state.data,
      search:'',
      flag:true,
    };
  },

  components: {},

  computed: {
    searchResults() {
      if(this.search=='') {
        for(let i = 0;i<this.blogs.length;i++) {
          this.blogs[i].id = i;
        }
        return this.blogs;
      }
      else {
        if(this.flag) {
            return this.blogs.filter((blog)=>{
            return blog.articleTitle.match(this.search)
          })
        }
        else {
            return this.blogs.filter((blog)=>{
            return blog.content.match(this.search)
          })
        }
      }
    }
  },

  methods: {
    text() {
      // console.log(this.blogs);
      // console.log(this.searchResults);
      // console.log(this.$store.state.data[1]);
      console.log(this.searchResults);
    },
    remove(index) {
      this.blogs.splice(index,1);
      this.$store.state.data=this.blogs;
      // this.blogs=this.$store.state.data
    },
    searchMode() {
      this.flag=!this.flag;
    },
    editor(index) {
      // console.log(this.$store.state.data[index].articleTitle);
      this.$store.state.tempdata.articleTitle=this.$store.state.data[index].articleTitle;
      this.$store.state.tempdata.content=this.$store.state.data[index].content;
      this.$store.state.tempdata.classification=this.$store.state.data[index].classification;
      // console.log(this.$store.state.tempdata.articleTitle);
      this.$store.state.tempdata.id=index;
      // console.log(this.$store.state.tempdata.id);
    },
    detail(item,index) {
      this.$store.state.detaildata.articleTitle=item.articleTitle;
      this.$store.state.detaildata.content=item.content;
      this.$store.state.detaildata.author=item.author;
      this.$store.state.detaildata.classification=item.classification;
      this.$store.state.detaildata.id=index;
      console.log(this.$store.state.data);
    }
  }
}

</script>
 
<style scoped>
 .a {
   height: 10px;
   background-color: #999;
 }

 .showBlog {
   width: 1080px;
   text-align: center;
 }

 h1 {
   width: 275px;
   font-size: 30px;
   color: #050000;
   border-bottom: 1px solid #000;
   padding-bottom: 12px;
   margin: 32px auto 0;
 }

 img {
   width: 45px;
   height: 45px;
 }

 h2 {
   font-weight: 400;
   margin: 45px 0 30px;
 }
 
 .blog {
   position: relative;
   float: left;
   width: 525px;
   height: 366px;
   font-size: 15px;
   color: #fff;
   text-align: left;
   background-color: #072833;
   padding: 20px 40px;
   margin: 45px auto 0;
   overflow: hidden;
 }

 .blog:nth-of-type(1) {
   margin-top: 71px;
 }

  .blog:nth-of-type(2n) {
    margin-left: 30px;
  }

 .search {
   width: 235px;
   height: 24px;
   outline: none;
   transform: translate(126px,-615px);
 }

 .searchMode {
   width: 24px;
   height: 26px;
   transform: translate(129px,-613px);
   border: 0;
   color: #fff;
   font-size: 18px;
   background-color: transparent;
   transition: all .8s;
 }

 .searchMode:hover {
   transform: translate(129px,-613px) rotateZ(180deg);
 }

 .content {
   height: 98px;
   overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 5;
   -webkit-box-orient: vertical;
 }

 .detial {
   position: absolute;
   display: inline-block;
   height: 30px;
   width: 44px;
   bottom: 31px;
   right: 13px;
   color: #fff;
   font-size: 26px;
 }
</style>